import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "./pages/Home";
import Linux from "./pages/Linux";
import Papers from "./pages/Papers";
import Projects from "./pages/Projects";
import Navigation from "./pages/Navigation";


function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Switch>
          <Route path="/" exact component={() => <Home />} />
          <Route path="/papers" exact component={() => <Papers />} />
          <Route path="/projects" exact component={() => <Projects />} />
          <Route path="/linux" exact component={() => <Linux />} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;