import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import remarkGemoji from 'remark-gemoji'

function Post(postId) {
    const [content, setContent] = useState("")

    import(`./posts/post${postId}.md`)
        .then(res => {
            fetch(res.default)
                .then(res => res.text())
                .then(res => setContent(res))
                .catch(err => console.log(err));
        })
        .catch(err => console.log(err));

    return (
        <div className="post" key={postId}>
            <ReactMarkdown
                remarkPlugins={[gfm, remarkGemoji]}
            >
                {content}
            </ReactMarkdown>
            <div style={{ borderBottomWidth: 1, color: 'black', flex: 1 }} />
        </div>
    )
}

export default Post;