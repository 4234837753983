import React from 'react';

import '../App.css';
import "../styles/projects.css";

import projects from '../content/projects.json'
import Project from "../content/Project"

class Projects extends React.Component {
  render() {
    return (
      <div className="content projects">
        {projects.map(data => Project(data))}
      </div>
    )
  }
}

export default Projects;
