import React from 'react';

function renderItem(item) {
    return (
        <li key={item.text}>
            <a href={item.link}>{item.text}</a>
        </li>
    )
};

function renderItems(items) {
    let list = []
    for (const item of items) {
        list.push(renderItem(item))
    }
    return list
};

function List(props) {
    return (
        <div className="list">
            <h4>{props.items.title}</h4>
            <ul>
                {renderItems(props.items.items)}
            </ul>
        </div>
    );
}

export default List;
