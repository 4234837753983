import React from 'react';
import List from './List';

function renderLists(lists) {
    let content = []
    for (const list of lists) {
        content.push(<List items={list} key={list.title}/>)
    }
    return content;
};

function Lists(props) {
    return (
        <div className="lists">
            {renderLists(props.lists)}
        </div>
    );
}

export default Lists;
