import React from 'react';
import '../App.css';

export default function Bio() {
    return (
        <div className="bio">
            <ul >
                <li>
                    Currently, Jack works at Paytronix Systems on the Online Ordering team as a Full-Stack Software Engineer. Responsibilities include software design, review, and maintenance of APIs and databases, as well as front-end views and middleware.
                </li>
                <li>
                    Designed and developed the <a href="https://kdl-umass.github.io/CausalGPSLC.jl">CausalGPSLC.jl</a> Julia package with colleagues for causal effect estimation using Gaussian processes in the presence of structured latent confounders.
                </li><li>
                    Applied causal effect estimation and conducted explainable artificial intelligence research in the <a href="https://kdl.cs.umass.edu/">Knowledge Discovery Lab</a> at CICS, UMass.
                </li><li>
                    Studied computer science, machine learning, and data science at <a href="https://cs.umass.edu/">UMass Amherst</a>.
                </li><li>
                    Worked at <a href="https://www.mathworks.com">MathWorks</a> on Docker-based cloud services and MATLAB source code.
                </li><li>
                    Conducted neural network research with the <a href="https://binds.cs.umass.edu/">BINDS Lab</a>.
                </li><li>
                    Hobbies include ceramic art, surfing, rock climbing, and instrumental music.
                </li><li>
                    Also, check out the <a href="https://BoleteFilter.github.io">Bolete Filter</a> that Jack and <a href="https://github.com/lbialik">Liza Bialik</a> have been working on!
                </li>
            </ul>
        </div>
    )
}