import React from 'react';

function Project(data) {
    return (
        <div className="project" key={data.title}>
            <h3 style={{ alignSelf: 'flex-start', marginRight: 50 }}>
                <a href={data.link}>
                    {data.title}
                </a>
            </h3>
            <p style={{ alignSelf: 'flex-end' }}>
                {data.description}
            </p>
        </div>
    )
}

export default Project;