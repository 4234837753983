import React from 'react';
import '../App.css';
import "../styles/papers.css";

import PapersList from '../content/PapersList';

export default class Papers extends React.Component {
  render() {
    return (
      <div className="content papers">
        <PapersList />
      </div>
    )
  }
}
