import React from 'react';
import Post from './Post'

const count = require('./posts/postCount.json')
const ids = [...Array(count.count).keys()].reverse()

function Blog() {
    return (
        <div className="blog">
            <div className='blog-header'>
                <p>
                    Hello and Welcome to my linux blog! I found that I keep re-searching things over and over again, 
                    so I'm going to collect my findings and fixes here for me (and you!) to come back to and find.
                    Enjoy!
                </p>
            </div>
            {ids.map(id => Post(id))}
        </div>
    )
}

export default Blog