import React from "react";
import { Link, withRouter } from "react-router-dom";

import "../App.css"
import "../styles/nav.css";

const navList = [
    { name: "Home", path: "" },
    { name: "Papers", path: "papers" },
    { name: "Projects", path: "projects" },
    { name: "Linux", path: "linux" },
]

function Navigation(props) {
    function navItem(name, path) {
        return (
            <li
                className={`nav-item  
                    ${props.location.pathname === "/" + path
                        ? "active" : ""}`}
                key={path}
            >
                <Link className="a" to={"/" + path}>
                    {name}
                </Link>
            </li>
        )
    }

    return (
        <nav className="nav">
            <ul className="list">
                {navList.map(item => navItem(item.name, item.path))}
            </ul>
        </nav>
    );
}

export default withRouter(Navigation);