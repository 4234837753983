import React from 'react';

import '../App.css';
import '../styles/papers.css';

export default function PapersList() {
    return (
        <div>
            <h1>Papers</h1>
            <ul class="papers-list content">
                <li>
                    Avery, K., Kenney, J., Amaranath, P., Cai, E., & Jensen, D. (2022). Measuring Interventional Robustness in Reinforcement Learning. arXiv preprint arXiv:2209.09058. [<a href="https://arxiv.org/pdf/2209.09058.pdf">pdf</a>]
                </li>
                <li>
                    Kenney, J., Valcore, J., Riggs, S., & Rietman, E. (2019). Deep Learning Regression of VLSI Plasma Etch Metrology.
                    arXiv preprint arXiv:1910.10067. [<a href="https://arxiv.org/pdf/1910.10067">pdf</a>]
                </li>
            </ul>
        </div>
    )
}