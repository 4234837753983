import React from 'react';
import '../App.css';
import "../styles/blog.css";

import Blog from '../blog/Blog';

class Linux extends React.Component {
  render() {
    return (
      <div className="content blog">
        <Blog />
      </div>
    )
  }
}

export default Linux;
